.main-btn {
  width: 185px;
  letter-spacing: 1.5px;
  border-radius: 3px;
  margin-top: 1rem;
}

.btn:hover {
  opacity: 0.8;
}

.credit-card {
  animation: float 3s ease-in-out infinite;
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}
