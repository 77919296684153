#header {
  position: relative;
  width: 220px;
  font-family: "Arial";
  height: 100%;
  display: table-cell;
}
#header .pro-sidebar {
  height: 100vh;
}

#header .pro-sidebar {
  width: 100%;
  min-width: 100%;
}
#header .pro-sidebar.collapsed {
  width: 80px;
  min-width: 80px;
}
#header .pro-sidebar-inner {
  background-image: linear-gradient(
    to bottom right,
    rgb(61, 223, 88),
    rgb(255, 255, 255)
  );
  background-color: rgb(170, 53, 53);
  box-shadow: 0.5px 0.866px 2px 0px rgba(0, 0, 0, 0.15);
}
#header .pro-sidebar-inner .pro-sidebar-layout {
  overflow-y: hidden;
}
#header .pro-sidebar-inner .pro-sidebar-layout .logotext p {
  font-size: 25px;
  padding: 0 20px;
  color: #000;
  font-weight: bold;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul {
  padding: 0 5px;
}
#header .pro-sidebar-inner .pro-sidebar-layout ul .pro-inner-item {
  color: #000;
  margin: 10px 0px;
  font-weight: bold;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper {
  background-color: #ffffff00;
  color: #000;
  border-radius: 3px;
}
#header
  .pro-sidebar-inner
  .pro-sidebar-layout
  ul
  .pro-inner-item
  .pro-icon-wrapper
  .pro-item-content {
  color: #000;
}
#header .pro-sidebar-inner .pro-sidebar-layout .active {
  background-image: linear-gradient(0deg, #00fe94 0%, #2cec1b 100%);
}
#header .logo {
  padding: 20px;
}

@media only screen and (max-width: 720px) {
  html {
    overflow: hidden;
  }
}
